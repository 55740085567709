import { Button, Result } from 'antd';
import { useEffect, useMemo, useRef } from 'react';
import { silentlyInitMsSdk } from 'services/microsoftTeamsHelpers';
import { getVersion } from 'services/utils';
import { useLogoutFromAllAccounts } from 'hooks/users';

import ThemeLayoutProvider from 'components/ThemeLayoutProvider';

function InternalErrorView({ error }) {
    const urlParams = new URLSearchParams(window.location.search);
    const wasRedirected = useRef(false);
    const handleLogout = useLogoutFromAllAccounts();

    const errorTypes = useMemo(
        () => [
            {
                name: 'ChunkLoadError',
                description:
                    'Seems like you have some network issues, please reload the page',
            },
            {
                name: 'idpiframe_initialization_failed',
                description:
                    "Seems like we can't authorize you with Google, please reload the page and try again",
            },
            {
                name: 'Request failed with status code 500',
                description: (
                    <>
                        Oops! We apologize for the inconvenience. It appears we
                        are having technical difficulties on our end. We are
                        working hard to fix this issue and get you back to the
                        site as soon as possible. Thank you for your patience.
                    </>
                ),
            },
            {
                name: 'access_denied: AADSTS65004',
                description: (
                    <>
                        Sorry, but without access to your Microsoft profile we
                        can't proceed with providing you service. Please provide
                        the required access to your profile so we can help you
                        better.
                    </>
                ),
            },
            {
                name: 'popup_closed_by_user',
                redirect: '/signin',
            },
            {
                name: 'FailedToOpenWindow',
                description:
                    'Seems like your browser blocks pop-ups from our app, thus we can not authenticate you. Please allow pop-ups in your browser',
            },
            {
                name: 'popup_window_error',
                description:
                    'Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser.',
            },
            {
                name: 'CancelledByUser',
                description:
                    'Authentication pop-up was closed, thus we can not authenticate you. Click "Reload page" button and start authentication flow again.',
            },
        ],
        [],
    );

    const propError = error && error.toString();
    const errorMessage =
        propError ||
        urlParams.get('message') ||
        'Oops, seems like you catch a new unknown error';

    const knownErrorType = useMemo(
        () =>
            errorTypes.find((type) =>
                errorMessage
                    ?.toLowerCase()
                    ?.includes(type?.name?.toLocaleLowerCase()),
            ),
        [errorTypes, errorMessage],
    );

    useEffect(() => {
        silentlyInitMsSdk();
    }, []);

    useEffect(() => {
        const errorRedirect = knownErrorType?.redirect;

        if (typeof window === undefined || !window.history) {
            return;
        }

        if (!wasRedirected.current) {
            window.history.replaceState(
                null,
                null,
                errorRedirect ? errorRedirect : '/',
            );
            wasRedirected.current = true;
        }
    }, [knownErrorType, wasRedirected]);

    return (
        <ThemeLayoutProvider
            className='flex h-screen w-full flex-col items-center justify-center'
            withAntd={true}>
            <Result
                className='md:max-w-[50%]'
                status='error'
                title={'Something went wrong'}
                subTitle={
                    <>
                        {knownErrorType?.description || (
                            <>
                                Sorry for the inconvenience. We are already
                                working on a fix. You can find some technical
                                details below. Try to refresh the page and maybe
                                it will gone, otherwise contact our support team
                                to quickly resolve the issue.
                            </>
                        )}
                        <p className='text-xs my-4'>
                            Technical details: {errorMessage.toString()}.
                            <br />
                            Perfect Wiki Version: {getVersion()}
                        </p>
                    </>
                }
                extra={[
                    <Button
                        key='reload-page'
                        onClick={() => window.location.replace('/')}>
                        Reload page
                    </Button>,
                    <Button key='logout' onClick={handleLogout}>
                        Sign Out
                    </Button>,
                ]}
            />
        </ThemeLayoutProvider>
    );
}

export default InternalErrorView;
