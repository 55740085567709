import { prompt } from 'services/googleSignInHelpers';
import { AppNotification } from 'components/Notification';
import { useEffect, useRef } from 'react';

export default function GoogleAuthButton({
    nextUrl = '/',
    onAuthorizationStart = () => {},
    onAuthorizationFinish = () => {},
}) {
    const signInWithGoogleBtnRef = useRef();

    useEffect(() => {
        if (!signInWithGoogleBtnRef.current) {
            return;
        }

        prompt({
            nextUrl,
            signInWithGoogleBtnEl: signInWithGoogleBtnRef.current,
            onAuthorizationStart: onAuthorizationStart,
            onAuthorizationFinish: onAuthorizationFinish,
        }).catch((err) => {
            const errorMessage = {
                auto_cancel: 'Auth flow cancelled',
                user_cancel: 'Auth flow cancelled',
                tap_outside: 'Auth flow cancelled',
                browser_not_supported: 'Your browser is not supported',
                secure_http_required: 'Google Auth requires https connection',
                unregistered_origin: `${window.location.hostname} is not valid origin for Google Auth`,
                unknown_reason: 'Something went wrong',
            };

            const ignoredErrors = new Set([
                'suppressed_by_user',
                'opt_out_or_no_session',
                'auto_cancel',
                'tap_outside',
            ]);

            if (
                !ignoredErrors.has(err.error) &&
                err.error !== 'unknown_reason'
            ) {
                AppNotification.warning({
                    message: 'Google Auth',
                    description: errorMessage[err.error] || err.error,
                    duration: 10,
                    placement: 'topLeft',
                });
            }
        });
    }, [
        signInWithGoogleBtnRef,
        nextUrl,
        onAuthorizationStart,
        onAuthorizationFinish,
    ]);

    return <div ref={signInWithGoogleBtnRef} id='google' />;
}
