import * as microsoftTeams from '@microsoft/teams-js';
import { initLanguageFromMsTeams } from './i18n';
import {
    saveMsThemeToLocalStorage,
    saveMsTeamsContextToLocalStorage,
} from './localStorage';

export const SUBENTITY_SEPARATOR = '_____heading____';
export const APP_ID = '40906836-4323-4bbe-875e-3cbb134c40a2';

export async function silentlyInitMsSdk() {
    try {
        if (!microsoftTeams.app.isInitialized()) {
            await microsoftTeams.app.initialize();
            microsoftTeams.app.notifyAppLoaded();
            microsoftTeams.app.notifySuccess();
        }

        return true;
    } catch (err) {
        const errMessage = err?.toString().toLowerCase();

        if (errMessage.includes('no parent window found')) {
            return false;
        }

        if (errMessage.includes('SDK initialization timed out')) {
            return false;
        }

        console.error(err);
        return false;
    }
}

export async function getMsTeamsContext() {
    const isTeamsInitialized = await silentlyInitMsSdk();
    if (!isTeamsInitialized) {
        return null;
    }

    const context = await microsoftTeams.app.getContext();
    microsoftTeams.app.registerOnThemeChangeHandler(() => {
        saveMsThemeToLocalStorage(context);
        window.location.reload();
    });

    saveMsThemeToLocalStorage(context);
    saveMsTeamsContextToLocalStorage(context);
    initLanguageFromMsTeams(context?.app?.locale);

    return context;
}

export const isMicrosoftTeamsLink = (link) => {
    if (!link) {
        return false;
    }

    const re = new RegExp(`^(https?:)?//teams.microsoft.com/.+$`, 'i');
    return re.test(link);
};

export const isMicrosoftTeamsLinkToPerfectWiki = (link) => {
    if (!link) {
        return false;
    }

    const re = new RegExp(
        `^(https?:)?//teams.microsoft.com/${APP_ID}/.+$`,
        'i',
    );
    return re.test(link);
};

export const getPayloadFromMicrosoftTeamsLinkToPerfectWiki = (link) => {
    if (!link || !isMicrosoftTeamsLink(link)) {
        return null;
    }

    const url = new URL(link);
    if (!url.search) {
        return null;
    }

    const result = new URLSearchParams(url.search);
    const context = result.get('context');

    if (!context) {
        return null;
    }

    try {
        const parsedContext = JSON.parse(context);
        const entity = parseEntity(parsedContext.subEntityId);

        if (!entity) {
            return parsedContext;
        }

        return {
            ...parsedContext,
            ...entity,
        };
    } catch (err) {
        console.warn(err);
        return null;
    }
};

export const executeDeepLink = (link) => {
    if (!link) {
        return;
    }
    microsoftTeams.executeDeepLink(link);
};

export function generateSubEntityId(pageId, heading) {
    if (!pageId) {
        return null;
    }

    if (heading) {
        return `${pageId}${SUBENTITY_SEPARATOR}${heading}`;
    }
    return `${pageId}`;
}

export const generateDeepLink = ({
    organizationId,
    channelId,
    pageId,
    pageSubHeading = '',
}) => {
    const isPersonal = organizationId === channelId;
    const subEntityId = generateSubEntityId(pageId, pageSubHeading);
    const entity = !isPersonal
        ? subEntityId
        : `personal_${JSON.stringify({ channelId, subEntityId })}`;
    const context = !isPersonal
        ? { subEntityId: entity, channelId }
        : { subEntityId: entity };
    const entityId = !isPersonal ? channelId : 'perfect-wiki-personal-tab';
    const encodedContext = encodeURIComponent(JSON.stringify(context));

    const result = `https://teams.microsoft.com/l/entity/${APP_ID}/${entityId}?context=${encodedContext}`;

    return result;
};

export function parseEntity(entity) {
    if (!entity) {
        return;
    }

    const PERSONAL_TAB_PREFIX = 'personal_';

    if (!entity.startsWith(PERSONAL_TAB_PREFIX)) {
        return parseSubEntityId(entity);
    }

    try {
        const entityJSON = entity.replace(PERSONAL_TAB_PREFIX, '');
        const payload = JSON.parse(entityJSON);

        return {
            ...payload,
            ...parseSubEntityId(payload.subEntityId),
        };
    } catch (err) {
        console.warn(err);

        return;
    }
}

const parseSubEntityId = (subEntityId) => {
    if (!subEntityId) {
        return {
            pageId: undefined,
        };
    }

    const chunks = (subEntityId + '').split(SUBENTITY_SEPARATOR);
    if (!chunks || chunks.length === 0) {
        return {
            pageId: undefined,
        };
    }

    if (chunks.length === 1) {
        return {
            pageId: chunks[0],
        };
    }

    if (chunks.length === 2) {
        return {
            pageId: chunks[0],
            heading: chunks[1],
        };
    }

    return {
        pageId: undefined,
    };
};
