import { ConfigProvider, theme } from 'antd';
import { useDarkMode } from './hooks';

export default function ThemeLayoutProvider({
    children,
    className,
    withWrapper = true,
    withAntd = false,
}) {
    const { isDarkTheme } = useDarkMode();

    let wrappedLayout = children;

    if (withWrapper) {
        wrappedLayout = (
            <div
                className={`dark:bg-mst-bg-dark-100 dark:text-white ${className}`}>
                {wrappedLayout}
            </div>
        );
    }

    if (withAntd) {
        wrappedLayout = (
            <ConfigProvider
                theme={
                    isDarkTheme && {
                        algorithm: theme.darkAlgorithm,
                    }
                }>
                {wrappedLayout}
            </ConfigProvider>
        );
    }

    return wrappedLayout;
}
