import { signOut as performGoogleLogout } from 'services/googleSignInHelpers';
import { logoutFromActiveAccount as performMicrosoftLogout } from 'services/msalHelpers';
import { clearSessionId } from './cookies';
import * as analytics from 'services/analytics';

export function logoutFromSession() {
    clearSessionId();
    performMicrosoftLogout();
    performGoogleLogout();
}

export function logoutFromAllAccounts(nextUrl) {
    clearSessionId();
    performMicrosoftLogout();
    performGoogleLogout();
    analytics.reset();

    if (!nextUrl || typeof nextUrl !== 'string') {
        window.location.replace('/signin');
    } else {
        window.location.replace(`/signin?next=${encodeURIComponent(nextUrl)}`);
    }
}
