import PwIcon from './assets/pw-icon.svg';
import { useDarkMode } from 'components/ThemeLayoutProvider/hooks';

function FullScreenLoading({ message, emptyContext }) {
    useDarkMode();
    return (
        <div
            className={`dark:bg-mst-bg-dark-100 dark:text-white
            bg-white w-full flex flex-col items-center justify-center
                ${emptyContext ? 'h-screen' : 'h-full'}
            `}>
            <div className='flex flex-col items-center justify-'>
                <img
                    src={PwIcon}
                    alt='Perfect Wiki Logo'
                    className={`animate-bounce w-32 h-32`}
                    loading='eager'
                />
                <p className='max-w-md text-gray-500 text-xs'>{message}</p>
            </div>
        </div>
    );
}

export default FullScreenLoading;
