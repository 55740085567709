import { Button, Input, Space, Typography } from 'antd';
import PwIcon from './assets/pw-icon.svg';
import { useCallback, useState } from 'react';
import { identifyUserV2 } from 'services/api';
import { useNav } from 'services/urlParams';
import { AppNotification } from 'components/Notification';

export default function OneTimeCodePage() {
    const [fetching, setFetching] = useState(false);
    const navigate = useNav();
    const handleOneTimeCode = useCallback(
        async (code) => {
            try {
                setFetching(true);
                await identifyUserV2({ token: code, provider: 'passwordless' });
                window?.location.replace('/'); // TODO: next url should be here
            } catch (err) {
                // TODO: display proper error
                AppNotification.error({ description: 'Code is incorrect' });
                console.error(err);
                setFetching(false);
            }
        },
        [setFetching],
    );

    return (
        <div className='w-full h-full flex items-center justify-center'>
            <div className='max-w-[300px] w-full flex flex-col items-center'>
                <img
                    src={PwIcon}
                    alt='Perfect Wiki Logo'
                    className={`w-32 h-32`}
                    loading='eager'
                />
                <Typography.Title className='text-center' level={4}>
                    Enter Code from Your E-Mail
                </Typography.Title>
                <form
                    className='w-full'
                    onSubmit={(e) => {
                        const codeField =
                            e.target.querySelector('input[type=number]');
                        const code = codeField?.value;

                        code && handleOneTimeCode(code);
                        e.preventDefault();
                        return false;
                    }}>
                    <Space direction='vertical' className='w-full'>
                        <Input
                            disabled={fetching}
                            required
                            autoFocus
                            size='large'
                            type='number'
                            minLength={6}
                            maxLength={6}
                            placeholder='Type code here'
                        />
                        <Button
                            type='primary'
                            htmlType='submit'
                            size='large'
                            className='w-full'
                            loading={fetching}>
                            Submit&nbsp;&rarr;
                        </Button>
                        <p className='text-xs w-full text-justify break-words text-gray-400'>
                            Check your inbox and SPAM folder. If you didn't
                            received message go back and enter your email again.{' '}
                            <Button
                                type='link'
                                size='small'
                                onClick={() => navigate('/signin')}
                                className='p-0 m-0 text-xs'>
                                Go Back
                            </Button>
                        </p>
                    </Space>
                </form>
            </div>
        </div>
    );
}
