import posthog from 'posthog-js';
import { isDebug } from './utils';

const POSTHOG_KEY = '-clq3R-5hmG_G9CDEBMAopOjq7YsfL4MphGrOGe24lY';

let posthogInstance = null;
const isDebugMode = isDebug();

export const init = () => {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    posthog.init(POSTHOG_KEY, {
        api_host: 'https://app.posthog.com',
        disable_session_recording: true,
        session_recording: {
            // WARNING: Only enable this if you understand the security implications
            recordCrossOriginIframes: true,
            maskAllInputs: false,
            maskInputOptions: {
                password: true,
            },
        },
    });
    posthogInstance = posthog;
};

export function startSessionRecording() {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    if (posthogInstance) {
        posthogInstance.startSessionRecording();
    }
}

export function stopSessionRecording() {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    if (posthogInstance) {
        posthogInstance.stopSessionRecording();
    }
}

export const identify = (email, data = {}) => {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    if (posthogInstance) {
        posthogInstance.identify(email, { email, ...data });
    }
};

export const alias = (aliasId) => {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    if (posthogInstance) {
        posthogInstance.alias(aliasId);
    }
};

export function reset() {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE');
        return;
    }

    if (posthogInstance) {
        posthogInstance.reset();
    }
}

export function isFeatureEnabled(featureName) {
    if (isDebugMode) {
        return true;
    }

    if (posthogInstance) {
        return posthogInstance.isFeatureEnabled(featureName);
    }
    return false;
}

export const capture = (eventName, payload = {}) => {
    if (isDebugMode) {
        console.warn('Analytics is disabled in DEBUG_MODE', {
            eventName,
            payload,
        });
        return;
    }

    if (posthogInstance) {
        posthogInstance.capture(eventName, payload);
    }
};
