export function getMsThemeFromLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return;
    }

    return localStorage.getItem('ms.theme');
}

export function getPWThemeFromLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return;
    }

    return localStorage.getItem('pw.theme');
}

export function saveMsTeamsContextToLocalStorage(context) {
    if (!isLocalStorageAvailable()) {
        return;
    }

    localStorage.setItem('pw.teams.context', JSON.stringify(context));
    return;
}

export function removeMsTeamsContextFromLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return;
    }

    localStorage.removeItem('pw.teams.context');
}

export function areWeRunningInsideMsTeamsPersonalTabOrChannelTab() {
    const context = getMsTeamsContextFromLocalStorage();

    if (!context) {
        return {
            isMsTeams: false,
            isMsTeamsPersonalPage: false,
            isMsTeamsTab: false,
        };
    }

    if (!context.channel) {
        return {
            isMsTeams: true,
            isMsTeamsPersonalPage: true,
            isMsTeamsTab: false,
        };
    }

    return {
        isMsTeams: true,
        isMsTeamsPersonalPage: false,
        isMsTeamsTab: true,
    };
}

export function getMsTeamsContextFromLocalStorage() {
    if (!isLocalStorageAvailable()) {
        return null;
    }

    try {
        const data = localStorage.getItem('pw.teams.context');
        const ctx = JSON.parse(data);

        return ctx;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export function saveMsThemeToLocalStorage(context) {
    if (!isLocalStorageAvailable()) {
        return;
    }

    localStorage.setItem(
        'ms.theme',
        context?.app?.theme === 'default' ? 'light' : 'dark',
    );
}

export function savePWThemeToLocalStorage(theme) {
    if (!isLocalStorageAvailable()) {
        return;
    }

    if (theme === 'auto') {
        localStorage.removeItem('pw.theme');
        return;
    }

    localStorage.setItem('pw.theme', theme);
}

const isPreferedThemeDark = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches;
};

export function detectDarkTheme() {
    if (!isLocalStorageAvailable()) {
        return false; // Defaults to Light Mode
    }

    const pwTheme = getPWThemeFromLocalStorage();
    const msTheme = getMsThemeFromLocalStorage();

    // priority to pw site or if it is not available
    // then priority to ms teams setting (in local storage)
    // or to the browsers prefers-color-scheme
    const isDarkTheme =
        pwTheme === 'dark' ||
        (!pwTheme &&
            msTheme !== 'light' &&
            (isPreferedThemeDark() || msTheme === 'dark'));

    return isDarkTheme;
}

export function isLocalStorageAvailable() {
    const storageType = 'localStorage';
    let storage;
    try {
        storage = window[storageType];
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
        );
    }
}
