import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { copyPage, copyMultiplePages } from 'services/api';
import { setCurrentDocument } from 'services/routerHelper';

import { useSetVisiblePageList } from 'hooks/cachedQueries';
import useGetChannelById from 'hooks/channels';

import { NotificationTypes } from 'components/Notification';
import { TaskName } from 'components/TasksWorker/types';

import { runNotification, useTasksWorkerState } from '..';

export function useCopyPagesTaskMutation() {
    const copyPagesMutation = useMutation(
        ({
            organizationId,
            channelId,
            toChannel,
            pageId,
            includeSubPages = true,
            parentId = null,
        }) => {
            return copyPage({
                organizationId,
                channelId,
                pageId,
                toChannel,
                includeSubPages,
                parentId,
            });
        },
        {
            onMutate: ({ keys, notificationKeyWords }) => {
                runNotification({
                    type: NotificationTypes.Info,
                    taskName: TaskName.CopyPages,
                    keys,
                    descriptionWords: notificationKeyWords,
                });
            },
            onError: (error, { keys, notificationKeyWords }) => {
                runNotification({
                    type: NotificationTypes.Error,
                    taskName: TaskName.CopyPages,
                    keys,
                    descriptionWords: {
                        ...notificationKeyWords,
                        error,
                    },
                });
            },
            onSettled: (_data, _error, _variables, context) => {
                if (context && context.closeCallback) {
                    context.closeCallback();
                }
            },
        },
    );

    return copyPagesMutation;
}

export function useCopyAllPagesTaskMutation() {
    const copyAllPagesMutation = useMutation(
        ({
            organizationId,
            channelId,
            toChannel,
            rootPagesIds,
            parentId = null,
        }) => {
            return copyMultiplePages({
                organizationId,
                channelId,
                rootPagesIds,
                toChannel,
                parentId,
            });
        },
        {
            onMutate: ({ keys }) => {
                runNotification({
                    type: NotificationTypes.Info,
                    taskName: TaskName.CopyAllPages,
                    keys,
                });
            },
            onError: (error, { keys }) => {
                runNotification({
                    type: NotificationTypes.Error,
                    taskName: TaskName.CopyAllPages,
                    keys,
                    descriptionWords: {
                        error,
                    },
                });
            },
            onSettled: (_data, _error, _variables, context) => {
                if (context && context.closeCallback) {
                    context.closeCallback();
                }
            },
        },
    );

    return copyAllPagesMutation;
}

export function useRunCopyAllPagesTask({
    organizationId,
    channelId,
    toChannel,
    userId,
    parentId,
    rootPagesIds,
}) {
    const setVisiblePageList = useSetVisiblePageList();
    const { mutateAsync } = useCopyAllPagesTaskMutation();
    const { addTaskToWorker } = useTasksWorkerState();

    const keys = useMemo(
        () => [organizationId, channelId, 'all-pages', userId],
        [organizationId, channelId, userId],
    );

    const runTask = useCallback(() => {
        mutateAsync({
            organizationId,
            channelId,
            toChannel,
            rootPagesIds,
            parentId,
            keys,
        }).then((data) => {
            const { taskId } = data;

            addTaskToWorker({
                id: taskId,
                name: TaskName.CopyAllPages,
                payload: {
                    keys,
                },
                successNotification: false,
                onSuccess: (data) => {
                    const { copiedPages } = data;

                    setVisiblePageList(toChannel, (old) => [
                        ...old,
                        ...copiedPages,
                    ]);

                    runNotification({
                        type: NotificationTypes.Success,
                        taskName: TaskName.CopyAllPages,
                        keys,
                        descriptionWords: {},
                    });
                },
            });
        });
    }, [
        mutateAsync,
        organizationId,
        channelId,
        toChannel,
        rootPagesIds,
        parentId,
        keys,
        addTaskToWorker,
        setVisiblePageList,
    ]);

    return runTask;
}

export function useRunCopyPagesTask({
    organizationId,
    channelId,
    toChannel,
    userId,
    pageId,
    pageTitle,
    includeSubPages,
    parentId,
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const setVisiblePageList = useSetVisiblePageList();

    const destinationChannel = useGetChannelById(toChannel);

    const { mutateAsync } = useCopyPagesTaskMutation();
    const { addTaskToWorker } = useTasksWorkerState();

    const keys = useMemo(
        () => [organizationId, channelId, pageId, userId],
        [organizationId, channelId, pageId, userId],
    );

    const notificationKeyWords = useMemo(() => ({ pageTitle }), [pageTitle]);

    const runTask = useCallback(() => {
        mutateAsync({
            organizationId,
            channelId,
            toChannel,
            includeSubPages,
            pageId,
            parentId,
            keys,
            notificationKeyWords,
        }).then((data) => {
            const { taskId } = data;

            addTaskToWorker({
                id: taskId,
                name: TaskName.CopyPages,
                payload: {
                    keys,
                    notificationKeyWords,
                },
                successNotification: false,
                onSuccess: (data) => {
                    const { copiedPages } = data;
                    const [firstPage] = copiedPages;

                    const toTeam =
                        destinationChannel.teamId ||
                        destinationChannel.organizationId;

                    setVisiblePageList(toChannel, (old) => [
                        ...old,
                        ...copiedPages,
                    ]);

                    runNotification({
                        type: NotificationTypes.Success,
                        taskName: TaskName.CopyPages,
                        keys,
                        descriptionWords: {
                            ...notificationKeyWords,
                            onLinkClick: () => {
                                setCurrentDocument(
                                    navigate,
                                    location,
                                    toTeam,
                                    toChannel,
                                    firstPage.id,
                                );
                            },
                        },
                    });
                },
            });
        });
    }, [
        mutateAsync,
        organizationId,
        channelId,
        toChannel,
        pageId,
        parentId,
        location,
        navigate,
        destinationChannel,
        setVisiblePageList,
        includeSubPages,
        addTaskToWorker,
        notificationKeyWords,
        keys,
    ]);

    return runTask;
}
