import { useParams } from 'react-router-dom';
import { parseEntity } from 'services/microsoftTeamsHelpers';

export function usePageContextFromParams() {
    const { teamId, channelId, subEntityId: paramsSubEntityId } = useParams();

    if (!paramsSubEntityId) {
        return {
            teamId,
            channelId,
        };
    }

    try {
        const {
            channelId: entityChannelId,
            pageId,
            heading,
        } = parseEntity(paramsSubEntityId);

        return {
            teamId,
            channelId: entityChannelId || channelId,
            pageId,
            heading,
        };
    } catch (err) {
        console.warn(err);
        return {
            teamId,
            channelId,
        };
    }
}
