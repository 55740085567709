import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { getTaskStatus } from 'services/api';

import { AppNotification, NotificationTypes } from 'components/Notification';

import { TaskInstance, TaskStatus } from '../types';

const CHECK_TASK_REFETCH_INTERVAL = 1 * 1000; // every second

const tasksState = atom([]);

export function useTasksWorkerState() {
    const [tasks, setTasks] = useAtom(tasksState);

    const addTaskToWorker = useCallback(
        (newTask) => {
            const isTaskAlreadyExists = tasks.find(
                (task) => task.id === newTask.id
            );

            if (!isTaskAlreadyExists) {
                setTasks([...tasks, newTask]);
            }
        },
        [tasks, setTasks]
    );

    const getTaskById = useCallback(
        (id) => {
            return tasks.find((task) => task.id === id);
        },
        [tasks]
    );

    const removeTask = useCallback(
        (id) => {
            setTasks(tasks.filter((task) => task.id !== id));
        },
        [tasks, setTasks]
    );

    return {
        tasks,
        addTaskToWorker,
        getTaskById,
        removeTask,
    };
}

export function useCheckTaskQuery({
    taskId,
    userId,
    refetchInterval = CHECK_TASK_REFETCH_INTERVAL,
}) {
    const { getTaskById } = useTasksWorkerState();

    return useQuery(['task', taskId], () => getTaskStatus(taskId, userId), {
        refetchInterval,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            const { state, message, ...result } = data;

            const task = getTaskById(taskId);
            const {
                name: taskName,
                payload,
                successNotification = true,
                onSuccess,
                onError,
            } = task;
            const { keys, notificationKeyWords } = payload;

            if (state === TaskStatus.Completed && result) {
                if (onSuccess) {
                    onSuccess(result);
                }

                if (successNotification) {
                    runNotification({
                        taskName,
                        type: NotificationTypes.Success,
                        descriptionWords: {
                            ...notificationKeyWords,
                            ...result,
                        },
                        keys,
                    });
                }
            }

            if (
                state === TaskStatus.Failed ||
                state === TaskStatus.NotFound ||
                state === TaskStatus.Delayed
            ) {
                if (onError) {
                    onError(message);
                }

                runNotification({
                    type: NotificationTypes.Error,
                    taskName,
                    keys,
                    descriptionWords: {
                        ...notificationKeyWords,
                        error: message,
                    },
                });
            }
        },
        onError: (error) => {
            AppNotification.error({
                description: `Our servers failed to complete your task. Try again later. (${error})`,
                duration: 10,
                placement: 'topRight',
            });
        },
    });
}

export function runNotification({ type, taskName, keys, descriptionWords }) {
    const taskInstance = TaskInstance[taskName];
    const { notifications } = taskInstance;
    const { getDescription, icon, duration } = notifications[type];

    AppNotification[type]({
        ...(icon && { icon }),
        ...(typeof duration !== 'undefined' && { duration }), // duration: 0 is also valid
        description: getDescription(descriptionWords),
        placement: 'topRight',
        className: 'task-notification',
        key: getNotificationKey([taskName, ...keys]),
    });
}

function getNotificationKey(keys) {
    return keys.join('-');
}
