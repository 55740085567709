import * as msal from '@azure/msal-browser';

function getMsalConfig(tenantId = 'common') {
    return {
        auth: {
            clientId: 'b12629b8-39be-4ad2-a60a-695050f95c10',
            authority: `https://login.microsoftonline.com/${tenantId}/`,
            navigateToLoginRequestUrl: false, // TODO: WAS CHANGED TO FALSE
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            console.error(message);
                            return;
                        case msal.LogLevel.Info:
                            console.info(message);
                            return;
                        case msal.LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case msal.LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            console.log(message);
                    }
                },
            },
        },
    };
}

const loginRequest = (loginHint) => {
    return {
        scopes: [
            'User.Read',
            'email',
            'profile',
            'Team.ReadBasic.All',
            'Channel.ReadBasic.All',
            'offline_access',
        ],
        prompt: 'select_account',
        loginHint: loginHint,
        redirectUri: window.location.origin + `/signin/microsoft/auth-end`,
    };
};

export function getMsalInstance(tenantId) {
    const msalInstance = new msal.PublicClientApplication(
        getMsalConfig(tenantId),
    );

    return msalInstance;
}

export async function authUserWithMsalPopup(loginHint, tenantId = undefined) {
    try {
        const msalInstance = getMsalInstance(tenantId);
        const msalResponse = await msalInstance.loginPopup({
            ...loginRequest(loginHint),
        });

        if (msalResponse) {
            msalInstance.setActiveAccount(msalResponse.account);
        }

        return msalResponse;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export async function authUserWithMsalRedirect(loginHint, tenantId = null) {
    try {
        const msalInstance = getMsalInstance(tenantId);
        const msalResponse = await msalInstance.loginRedirect({
            ...loginRequest(loginHint),
        });

        if (msalResponse) {
            msalInstance.setActiveAccount(msalResponse.account);
        }

        return msalResponse;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export function logoutFromActiveAccount(tenantId = undefined) {
    const msalInstance = getMsalInstance(tenantId);
    msalInstance.setActiveAccount(null);
}
