import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { t } from 'i18next';

import packageInfo from '../../package.json';
import { copy } from './copyToClipboard';

dayjs.extend(localizedFormat);

export function getVersion() {
    return packageInfo?.version || 'beta';
}

export const getHumanReadableTimeDiff = (changedIsoStr) => {
    if (!changedIsoStr) {
        return t('today', 'today');
    }

    const d = dayjs(changedIsoStr);
    const diffInDays = dayjs().diff(d, 'days');
    const diffInWeeks = dayjs().diff(d, 'weeks');

    if (diffInDays <= 1) {
        return t('updated.today', 'today');
    }

    if (diffInDays < 7) {
        return t('updated.daysAgo', 'a few days ago');
    }

    if (diffInWeeks < 4) {
        return t('updated.weeksAgo', 'over a week ago');
    }

    return t('updated.monthsAgo', 'over a month ago');
};

export function isDebug() {
    return process.env.REACT_APP_ENV === 'DEBUG';
}

export function firestoreTimestampToDate(timestamp) {
    if (!timestamp || !timestamp._seconds) {
        return undefined;
    }
    return new Date(timestamp._seconds * 1000);
}

export function activeUsersFilter(user) {
    return (
        user.lastLoginDateTime &&
        dayjs().diff(firestoreTimestampToDate(user.lastLoginDateTime), 'days') <
            30
    );
}

export function getUserNameFromEmail(email) {
    const MAX_USERNAME_LEN = 12;
    if (!email) {
        return '';
    }

    const userName = email.split('@')[0];

    return userName.slice(0, MAX_USERNAME_LEN);
}

export function returnTrueIfUndefined(variable) {
    if (typeof variable === 'undefined') {
        return true;
    }

    return variable;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min) + min);
}

export function openLinkInNewTab(link) {
    window.open(link, '_blank');
}

export function disableIosScroll(isModalOpen) {
    if (typeof window !== 'undefined') {
        isModalOpen
            ? document?.body?.classList?.add('ios-scroll-disabled')
            : document?.body?.classList?.remove('ios-scroll-disabled');
    }
}

export function copyAsHtml(html) {
    copy(html, {
        format: 'text/html',
        plainText: html,
    });
}
export function copyToClipboardAsAnchor(title, href) {
    copy(`<a href='${href}'>${title}</a><br/>`, {
        format: 'text/html',
        plainText: href,
    });
}

export function isEmailValid(email) {
    if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }

    return false;
}
