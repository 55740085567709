import { Button } from 'antd';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { logoutFromAllAccounts } from 'services/authorization';
import { updateUserPreferences, updateUserName } from 'services/api';

import { useApplicationContextState } from 'components/ApplicationContext/hooks';

import { AppNotification } from 'components/Notification';

export function useUpdateUserNameMutation() {
    const { getOrganization, getUser } = useApplicationContextState();
    const { id: tid } = getOrganization();
    const { updateUserName: updateUserNameInAppContext } = getUser();

    const mutation = useMutation(
        ({ userId, name }) => {
            return updateUserName({ organizationId: tid, userId, name });
        },
        {
            onSuccess: (data) => {
                updateUserNameInAppContext(data.result.name);
            },
        },
    );

    return mutation;
}

export function useUpdateUserPreferencesMutation() {
    const { getOrganization, getUser } = useApplicationContextState();
    const { id: tid } = getOrganization();
    const { setUserPreferences } = getUser();

    const mutation = useMutation(
        ({ preferences }) => {
            return updateUserPreferences(tid, preferences);
        },
        {
            onSuccess: (data) => {
                setUserPreferences(data.result.preferences);
            },
        },
    );

    return mutation;
}

export function useLogoutFromAllAccounts() {
    return useCallback((nextUrl) => {
        logoutFromAllAccounts(nextUrl);
    }, []);
}

export function useWrongAccountWarning() {
    const handleLogout = useLogoutFromAllAccounts();

    const showWarning = useCallback(
        (originalUrl) => {
            AppNotification.warning({
                key: 'channel-not-found-warning',
                description: t(
                    'Layout.KbNotFound',
                    'Knowledge base or page that you requested is not found. Maybe you logged in with a wrong account?',
                ),
                duration: 0,
                btn: (
                    <Button
                        type='link'
                        size='small'
                        onClick={() => handleLogout(originalUrl)}>
                        {t(
                            'Layout.SignInWinthAnotherAccount',
                            'Sign In With Another Account',
                        )}
                    </Button>
                ),
            });
        },
        [handleLogout],
    );

    return {
        showWarning,
    };
}
