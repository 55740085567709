import { useEffect } from 'react';
import { identifyUserV2 } from 'services/api';
import { authUserWithMsalPopup } from 'services/msalHelpers';
import { useNav } from 'services/urlParams';

import FullScreenLoading from 'components/FullScreenFetching';

const SignInWithMicrosoft = () => {
    const navigate = useNav();

    useEffect(() => {
        async function startAuthFlow() {
            const urlParams = new URLSearchParams(window.location.search);
            const nextUrl = urlParams.get('next');

            try {
                const response = await authUserWithMsalPopup();

                if (response) {
                    await identifyUserV2({
                        provider: 'microsoft',
                        token: response.accessToken,
                    });

                    window.location.replace(
                        nextUrl && nextUrl !== null ? nextUrl : '/',
                        '_self',
                    );
                }
            } catch (err) {
                if (err.errorCode === 'user_cancelled') {
                    navigate(
                        `/signin?message=${encodeURIComponent(
                            'Authenticaiton popup was closed by the user.',
                        )}`,
                    );
                } else if (err.errorCode === 'interaction_in_progress') {
                    navigate(
                        `/signin?message=${encodeURIComponent(
                            'Seems like another authentication process is already running. Please, close all other authentication popups and open Perfect Wiki in a new browser tab',
                        )}`,
                    );
                } else if (err.errorCode === 'block_nested_popups') {
                    navigate(
                        `/signin?message=${encodeURIComponent(
                            'Authentication with Microsoft failed because Perfect Wiki was running in a popup window. Reload the page and try again.',
                        )}`,
                    );
                } else {
                    navigate(
                        `/error?message=${encodeURIComponent(
                            err?.response?.data?.message ||
                                err ||
                                'Failed to authenticate with Microsoft',
                        )}`,
                    );
                }
            }
        }

        startAuthFlow();
    }, [navigate]);

    return <FullScreenLoading message='Signing you in with Microsoft...' />;
};

export default SignInWithMicrosoft;
