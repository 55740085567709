import axios from 'axios';

import { clearSessionId } from './cookies';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const axiosWithCookies = axios.create({
    withCredentials: true,
});

// Global handler in one place for all the responses with non-valid token sent
// Should go to sign in in this case
axiosWithCookies.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            console.log('ERROR 401', error);
            window.open(
                `/signin?next=${encodeURIComponent(window.location.href)}`,
                '_self',
            );
            clearSessionId();

            return;
        }

        return Promise.reject(error);
    },
);

export const suggest = async ({
    organizationId,
    query,
    channels = null,
    tags = null,
    currentChannelId,
}) => {
    let queryString = `?q=${encodeURIComponent(query)}`;
    if (channels && channels.length > 0) {
        queryString += `&channels=${encodeURIComponent(channels.join())}`;
    }

    if (tags && tags.length > 0) {
        queryString += `&tags=${encodeURIComponent(tags.join())}`;
    }

    if (currentChannelId) {
        queryString += `&currentChannelId=${encodeURIComponent(
            currentChannelId,
        )}`;
    }

    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/organization/${encodeURIComponent(
            organizationId,
        )}/suggest${queryString}`,
        {},
    );
    return response?.data;
};

export const searchByTitle = async ({
    organizationId,
    query,
    tags,
    channelId,
}) => {
    let queryString = `?q=${encodeURIComponent(query)}`;

    if (tags && tags.length > 0) {
        queryString += `&tags=${encodeURIComponent(tags.join())}`;
    }

    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v3/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${channelId}/search/title${queryString}`,
        {},
    );
    return response?.data;
};

export const search = async ({
    organizationId,
    query,
    channels = null,
    tags = null,
    currentChannelId,
}) => {
    let queryString = `?q=${encodeURIComponent(query)}`;
    if (channels && channels.length > 0) {
        queryString += `&channels=${encodeURIComponent(channels.join())}`;
    }

    if (tags && tags.length > 0) {
        queryString += `&tags=${encodeURIComponent(tags.join())}`;
    }

    if (currentChannelId) {
        queryString += `&currentChannelId=${encodeURIComponent(
            currentChannelId,
        )}`;
    }

    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/organization/${encodeURIComponent(
            organizationId,
        )}/search${queryString}`,
    );
    return response?.data;
};

export const getPageHtmlUri = ({ teamId, pageId, ...queryParams }) => {
    const params = new URLSearchParams(queryParams);

    return `${API_BASE_URL}/wiki/files/${encodeURIComponent(
        teamId,
    )}/${pageId}.html?${params.toString()}`;
};

export const getReaderPreviewUri = ({ organizationId, channelId, pageId }) => {
    return `https://read.perfectwiki.xyz/w/${organizationId}/${channelId}/${
        pageId ? pageId : ''
    }`;
};

export const getHtmlUri = (teamId, pageId, version = null, toc = false) => {
    if (version) {
        return `${API_BASE_URL}/wiki/files/${encodeURIComponent(
            teamId,
        )}/${pageId}.html?version=${encodeURIComponent(version)}`;
    }

    if (!toc) {
        return `${API_BASE_URL}/wiki/files/${encodeURIComponent(
            teamId,
        )}/${pageId}.html`;
    }

    return `${API_BASE_URL}/wiki/files/${encodeURIComponent(
        teamId,
    )}/${pageId}.html?toc=true`;
};

export const copyMultiplePages = async ({
    organizationId,
    channelId,
    rootPagesIds,
    toChannel,
    parentId,
}) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/pages/copy`,
        {
            toChannel,
            rootPagesIds,
            parentId,
        },
        {},
    );

    return response?.data;
};

export const copyPage = async ({
    organizationId,
    channelId,
    pageId,
    toChannel,
    includeSubPages,
    parentId,
}) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/page/${pageId}/copy`,
        {
            toChannel,
            includeSubPages,
            parentId,
        },
        {},
    );

    return response?.data;
};

export const movePage = async ({
    organizationId,
    channelId,
    pageId,
    toChannel,
    parentId,
}) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/page/${pageId}/move`,
        {
            toChannel,
            parentId,
        },
        {},
    );

    return response?.data;
};

export const addPage = async (organizationId, channelId, pageData, userId) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page`,
        {
            ...pageData,
            docState: 'visible',
        },
        {},
    );

    return response?.data;
};

export const updatePage = async (
    organizationId,
    channelId,
    pageId,
    pageData,
    userId,
    comment,
) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page/${pageId}`,
        pageData,
        {
            headers: {
                'X-USER-COMMENT': comment,
            },
        },
    );

    return response?.data;
};

export const getAllTags = async ({ organizationId, channelId }) => {
    const searchQuery = channelId
        ? `?channels=${encodeURIComponent(channelId)}`
        : '';

    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/wiki/organization/${organizationId}/tags${searchQuery}`,
        {},
    );

    return response?.data;
};

export const updatePageTags = async ({
    organizationId,
    channelId,
    pageId,
    userId,
    tags,
}) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/wiki/organization/${organizationId}/channel/${channelId}/page/${pageId}/tags`,
        { tags },
        {},
    );

    return response?.data;
};

export const updateUserPreferences = async (organizationId, preferences) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/users`,
        { preferences },
    );

    return response?.data;
};

export const updateUserName = async ({ organizationId, userId, name }) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/users/name`,
        { userId, name },
    );

    return response?.data;
};

export const inviteUser = async ({ organizationId, email }) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/users/invite`,
        {
            email,
        },
        {},
    );

    return response?.data;
};

export const updateUserRole = async ({
    organizationId,
    userIdToUpdate,
    role,
}) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/users/role`,
        {
            userId: userIdToUpdate,
            role,
        },
        {},
    );

    return response?.data;
};

export const getOrganizationUsers = async (organizationId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/users`,
        {},
    );

    return response?.data;
};

export const createOrganizationChannel = async (
    organizationId,
    channelId,
    payload,
) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organizations/${encodeURIComponent(
            organizationId,
        )}/channels/${encodeURIComponent(channelId)}`,
        { channel: payload },
        {},
    );

    return response?.data;
};

export const deleteOrganizationChannel = async (
    organizationId,
    channelId,
    userId,
) => {
    const response = await axiosWithCookies.delete(
        `${API_BASE_URL}/organizations/${encodeURIComponent(
            organizationId,
        )}/channels/${encodeURIComponent(channelId)}`,
        {},
    );

    return response?.data;
};

export const getOrganizationChannel = async (
    organizationId,
    channelId,
    userId,
) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/organizations/${encodeURIComponent(
            organizationId,
        )}/channels/${encodeURIComponent(channelId)}`,
        {},
    );

    return response?.data;
};

export const updateOrganizationChannel = async (
    organizationId,
    channelId,
    payload,
) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organizations/${encodeURIComponent(
            organizationId,
        )}/channels/${encodeURIComponent(channelId)}`,
        { channel: payload },
        {},
    );

    return response?.data;
};

export const sendPageView = async ({ organizationId, channelId, pageId }) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/views/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page/${pageId}`,
        {},
        {},
    );

    return response?.data;
};

export const getPageVersions = async (organizationId, channelId, pageId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            organizationId,
        )}/${encodeURIComponent(channelId)}/${pageId}/versions`,
    );

    return response?.data;
};

export const revertPageToVersion = async (
    organizationId,
    channelId,
    pageId,
    versionId,
    userId,
) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(
            channelId,
        )}/page/${pageId}/version/${encodeURIComponent(versionId)}`,
        {},
        {},
    );
    return response?.data;
};

export const trashPages = async (organizationId, channelId, pages, comment) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            organizationId,
        )}/${encodeURIComponent(channelId)}/hide`,
        pages,
        {
            headers: {
                'X-USER-COMMENT': comment,
            },
        },
    );

    return response?.data;
};

export const removePages = async (organizationId, channelId, pages, userId) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/remove`,
        pages,
        {},
    );

    return response?.data;
};

export const updatePagesBulk = async (teamId, channelId, pages) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            teamId,
        )}/${encodeURIComponent(channelId)}/bulk`,
        pages,
    );

    return response?.data;
};

export const importFiles = async (
    organizationId,
    channelId,
    files,
    type,
    userId,
    preview = false,
) => {
    const formData = new FormData();

    files.forEach((file) => {
        formData.append(file.name, file);
    });

    let url = `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/import/${type}`;
    if (preview) {
        url += '?preview=true';
    }

    const response = await axiosWithCookies.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response?.data;
};

export const updateOrganizationPreferences = async (
    organizationId,
    payload,
) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/wiki/${organizationId}/preferences`,
        { organization: { ...payload } },
        {},
    );

    return response?.data;
};

/* OBSOLETE */
export const getUploadUrl = (organizationId, pageId) => {
    return `${API_BASE_URL}/wiki/files/${encodeURIComponent(
        organizationId,
    )}/${encodeURIComponent(pageId)}`;
};

export const getUploadUrlV2 = (organizationId, pageId) => {
    return `${API_BASE_URL}/v2/files/organization/${encodeURIComponent(
        organizationId,
    )}/page/${encodeURIComponent(pageId)}`;
};

/* TODO: IMPLEMENT */
export const uploadFileV2 = async (organizationId, pageId, file) => {
    const formData = new FormData();

    formData.append('upload', file);

    const response = await axiosWithCookies.post(
        getUploadUrlV2(organizationId, pageId),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );

    return response?.data;
};

/* OBSOLETE */
export const uploadFile = async (organizationId, pageId, file) => {
    const formData = new FormData();

    formData.append('upload', file);

    const response = await axiosWithCookies.post(
        getUploadUrl(organizationId, pageId),
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );

    return response?.data;
};

export const getHiddenWikiPages = async (teamId, channelId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            teamId,
        )}/${encodeURIComponent(channelId)}/hidden`,
    );

    return response?.data;
};

export const getVisibleWikiPages = async (teamId, channelId, userId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            teamId,
        )}/${encodeURIComponent(channelId)}/visible`,
        {},
    );

    return response?.data;
};

export const getWikiPage = async (teamId, channelId, pageId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/wiki/${encodeURIComponent(
            teamId,
        )}/${encodeURIComponent(channelId)}/${encodeURIComponent(pageId)}`,
    );
    return response?.data;
};

export const getPaymentLink = (email) => {
    return `https://perfectwikiforteams.com/pricing?email=${encodeURIComponent(
        email,
    )}`;
};

export const getManagementPortalLink = (organizationId) => {
    return `${API_BASE_URL}/wiki/${encodeURIComponent(
        organizationId,
    )}/payments/management`;
};

export const activateCode = async (activationCode, organizationId, userId) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/wiki/payments/activate`,
        {
            organizationId,
            activationCode,
        },
        {
            validateStatus: false,
        },
    );

    return response.status === 200;
};

export const getUserSession = async () => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v3/users/session`,
    );

    return response?.data?.session;
};

export const identifyUserV2 = async ({ token, provider }) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/v2/users/identify/${provider}`,
        {},
        {
            headers: {
                Authorization: token,
            },
        },
    );

    return response?.data;
};

export const sendOneTimeCode = async ({ email }) => {
    const response = await axiosWithCookies.post(`${API_BASE_URL}/users/code`, {
        email,
    });

    return response?.data;
};

export const getOrganizationTemplates = async ({ organizationId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/organization/${organizationId}/templates`,
    );

    return response?.data?.templates;
};

export const getLinkToTheWiki = (
    teamId,
    channelId,
    baseUrl = window.location.origin,
    pageId = null,
    pageSubHeading = null,
) => {
    let url = `${baseUrl}/?teamId=${teamId}&channelId=${channelId}`;
    const SUBENTITY_SEPARATOR = '_____heading____';

    if (pageId || pageSubHeading) {
        const subEntity = !pageSubHeading
            ? pageId
            : `${pageId}${SUBENTITY_SEPARATOR}${pageSubHeading}`;
        url += `&subEntityId=${subEntity}`;
    }

    return url;
};

export const runChannelGenerateHtmlExportTask = async ({
    organizationId,
    channelId,
}) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/v2/organization/${organizationId}/channel/${channelId}/html/zip`,
        null,
        {},
    );

    return response?.data;
};

export const runChannelGeneratePdfExportTask = async ({
    organizationId,
    channelId,
}) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/v2/organization/${organizationId}/channel/${channelId}/pdf/zip`,
        null,
        {},
    );

    return response?.data;
};

export const runGeneratePdfExportTask = async ({
    organizationId,
    channelId,
    pageId,
}) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/page/${pageId}/pdf
`,
        null,
        {},
    );

    return response?.data;
};

export const runGenerateArchivedHtmlTask = async ({
    organizationId,
    channelId,
    userId,
}) => {
    const response = await axiosWithCookies.put(
        `
        ${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/html/zip
    `,
        null,
        {},
    );

    return response?.data;
};

export const runGenerateArchivedPdfTask = async ({
    organizationId,
    channelId,
    userId,
}) => {
    const response = await axiosWithCookies.put(
        `
        ${API_BASE_URL}/organization/${organizationId}/channel/${channelId}/pdf/zip
    `,
        null,
        {},
    );

    return response?.data;
};

export const checkTaskStatus = async (taskId) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/tasks/${taskId}/check`,
    );

    return response?.data;
};

export const getTaskStatus = async (taskId, userId) => {
    const response = await axiosWithCookies.get(
        `
        ${API_BASE_URL}/wiki/tasks/${taskId}/check
    `,
        {},
    );

    return response?.data;
};

export const sendChatMessageToAddUser = async ({
    organizationId,
    usersCount,
    userId,
}) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/integrations/notification`,
        {
            organizationId,
            usersCount,
        },
        {},
    );

    return response?.data;
};

export const generateContentWithAI = async ({ userId, prompt }) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/integrations/generate`,
        {
            prompt,
        },
        {},
    );

    return response?.data;
};

export const getPagesActionsForLast7Days = async ({ organizationId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/pages`,
    );

    return response?.data;
};

export const getOrganizationCommentsForLast7Days = async ({
    organizationId,
}) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/comments`,
    );

    return response?.data;
};

export const getAIChatBotQueries = async ({
    organizationId,
    type = 'week',
}) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/bot_queries?type=${type}`,
    );

    return response?.data;
};

export const getRelatedPages = async ({
    organizationId,
    channelId,
    pageId,
}) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/internal/organization/${organizationId}/channel/${channelId}/page/${pageId}/related`,
    );

    return response?.data;
};

export const getPageViewsForLast7Days = async ({ organizationId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/views`,
    );

    return response?.data;
};

export const getSearchQueriesForLast7Days = async ({ organizationId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/search_queries`,
    );

    return response?.data;
};

export const getComments = async ({ organizationId, channelId, pageId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/channel/${channelId}/page/${pageId}/comment`,
    );

    return response?.data;
};

export const getPageViews = async ({ organizationId, channelId, pageId }) => {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/channel/${channelId}/page/${pageId}/unique_views`,
    );

    return response?.data;
};

export const sendComment = async ({
    organizationId,
    channelId,
    pageId,
    message,
}) => {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/v2/comments/organization/${organizationId}/channel/${channelId}/page/${pageId}`,
        {
            message,
        },
    );

    return response?.data;
};

export const restoreComment = async ({ organizationId, eventId }) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/event/${eventId}`,
        {
            isActive: true,
        },
    );

    return response?.data;
};

export const deleteComment = async ({ organizationId, eventId }) => {
    const response = await axiosWithCookies.put(
        `${API_BASE_URL}/v2/events/organization/${organizationId}/event/${eventId}`,
        {
            isActive: false,
        },
    );

    return response?.data;
};

export async function createPublicLink({ organizationId, channelId, pageId }) {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/public/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page/${encodeURIComponent(
            pageId,
        )}/publish`,
        {},
        {},
    );

    return response?.data;
}

export async function createExpirablePublicLink({
    organizationId,
    channelId,
    pageId,
}) {
    const response = await axiosWithCookies.post(
        `${API_BASE_URL}/preview/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page/${encodeURIComponent(
            pageId,
        )}/publish`,
        {},
        {},
    );

    return response?.data;
}

export async function getPublicLink({ organizationId, channelId, pageId }) {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/public/organization/${encodeURIComponent(
            organizationId,
        )}/channel/${encodeURIComponent(channelId)}/page/${encodeURIComponent(
            pageId,
        )}/publish`,
        {},
    );

    return response?.data;
}

export async function removePublicLink({ hash }) {
    const response = await axiosWithCookies.delete(
        `${API_BASE_URL}/public/page/${hash}`,
        {},
    );

    return response?.data;
}

export async function askInternalBot({ organizationId, query, channelId }) {
    let url = `${API_BASE_URL}/internal/organization/${encodeURIComponent(organizationId)}/bot?q=${encodeURIComponent(query)}`;
    if (channelId) {
        url += `&channelId=${encodeURIComponent(channelId)}`;
    }

    const response = await axiosWithCookies.post(url, {}, {});
    return response?.data;
}

export async function getChatBotQueriesByUser({ organizationId }) {
    const response = await axiosWithCookies.get(
        `${API_BASE_URL}/v2/events/organization/${encodeURIComponent(organizationId)}/bot_queries/user`,
    );

    return response?.data;
}
