import { Provider as AtomProvider } from 'jotai';

import InternalizationProvider from 'components/InternalizationProvider';

export default function ApplicationProvider({ children }) {
    return (
        <AtomProvider>
            <InternalizationProvider>{children}</InternalizationProvider>
        </AtomProvider>
    );
}
