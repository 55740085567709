import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const COOKIES_LIST = {
    NewUsersToAdd: 'pw.newUsersToAdd',
    SessionId: 'PW.SESSION_ID',
};

const COOKIES_CONFIG = {
    path: '/',
    domain: 'perfectwiki.xyz',
    secure: true,
    sameSite: 'none',
};

export function setCookie(name, value, options) {
    cookies.set(name, value, { ...COOKIES_CONFIG, ...options });
}

export function getCookie(name) {
    return cookies.get(name);
}

export function removeCookie(name) {
    cookies.remove(name, COOKIES_CONFIG);
}

export function hasSessionId() {
    const sessionId = cookies.get(COOKIES_LIST.SessionId);
    return sessionId;
}

export function clearSessionId() {
    cookies.remove(COOKIES_LIST.SessionId, COOKIES_CONFIG);
}
