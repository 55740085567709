import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getFirstVisibleDocumentId } from 'services/documents';
import { setCurrentDocument } from 'services/routerHelper';

import {
    useGetVisiblePages,
    useGetVisiblePagesCallback,
} from 'hooks/cachedQueries';

import { useApplicationContextState } from 'components/ApplicationContext/hooks';
import { useRunCopyPagesTask } from 'components/TasksWorker/hooks/copyPages';

export function useToggle(initialValue = false) {
    const [value, setValue] = useState(initialValue);
    const toggle = useCallback(() => {
        setValue((v) => !v);
    }, []);
    return [value, toggle];
}

export function useIsMobile() {
    return useMediaQuery({ query: '(max-width: 768px)' });
}

export function useDuplicatePageMutation(
    organizationId,
    channelId,
    currentPageId,
    title,
    userId,
) {
    const pages = useGetVisiblePages(channelId);
    const { parentId } = pages.find((p) => p.id === currentPageId) || {};

    const runCopyPagesTask = useRunCopyPagesTask({
        organizationId,
        channelId,
        toChannel: channelId,
        pageId: currentPageId,
        pageTitle: title,
        includeSubPages: true,
        parentId,
        userId,
    });

    return {
        mutate: () => runCopyPagesTask(),
        isLoading: runCopyPagesTask.isLoading,
    };
}

export function useGetUserRole(channelId) {
    const { channelsList } = useChannelsList();
    const channel = channelsList.find((ch) => ch.channelId === channelId);
    if (!channel) {
        return {
            userRole: 'viewer',
            isViewer: true,
            isEditor: false,
        };
    }
    const { userRole, isViewer, isEditor } = channel;

    return {
        userRole,
        isViewer,
        isEditor,
    };
}

export function useChannelsListWithEditorRights() {
    const { channelsList } = useChannelsList();

    return {
        channelsList: channelsList.filter((ch) => ch.isEditor),
    };
}

export function useChannelsList(includeArchived = false) {
    const { getOrganization, getAccessibleChannels } =
        useApplicationContextState();

    const params = useParams();
    const channelId = params.channelId;
    const channels = getAccessibleChannels(includeArchived);
    const { id: tid } = getOrganization();

    const channelsList = useMemo(() => {
        if (!channels) {
            return [];
        }

        let result = channels.map((ch) => {
            return {
                ...ch,
                teamId: ch.teamId || tid,
                userRole: ch.role,
                isViewer: ch.role === 'viewer',
                isEditor: ch.role === 'editor',
            };
        });

        result = result.sort((channelA, channelB) => {
            return channelA.name.localeCompare(channelB.name);
        });

        return result;
    }, [channels, tid]);

    const currentChannel = useMemo(
        () => channelsList.find((ch) => ch.channelId === channelId),
        [channelsList, channelId],
    );

    const accessibleChannelsIds = useMemo(
        () => channelsList.map((ch) => ch.channelId),
        [channelsList],
    );

    let result = useMemo(
        () => ({
            channelsList,
            currentChannel,
            accessibleChannelsIds,
        }),
        [channelsList, currentChannel, accessibleChannelsIds],
    );

    return result;
}

function getDefaultPageId(pages) {
    const visibleRootDocs = pages.filter((doc) => doc.parentId === null);
    const firstVisiblePageId = getFirstVisibleDocumentId(visibleRootDocs);

    return firstVisiblePageId;
}

export function useNavigateToDefaultPage(teamId, channelId) {
    const navigate = useNavigate();
    const location = useLocation();

    const getVisiblePages = useGetVisiblePagesCallback();

    const result = useCallback(() => {
        const visiblePages = getVisiblePages(channelId);
        const nextPageId = getDefaultPageId(visiblePages);
        setCurrentDocument(navigate, location, teamId, channelId, nextPageId);
    }, [navigate, location, getVisiblePages, teamId, channelId]);

    return result;
}
