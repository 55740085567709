import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import ApplicationProvider from 'components/ApplicationProvider';
import FullScreenLoading from 'components/FullScreenFetching';

import Router from './routes';
import * as analytics from 'services/analytics';
import './services/i18n';

// Create a client
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            console.error(error);
        },
    }),
    mutationCache: new MutationCache({
        onError: (error, variables, context, mutation) => {
            console.error(error);
        },
    }),
});

analytics.init();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <QueryClientProvider client={queryClient}>
        <ApplicationProvider>
            <Suspense fallback={<FullScreenLoading emptyContext={true} />}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </Suspense>
        </ApplicationProvider>
    </QueryClientProvider>,
);
