import { VISIBILITY_FILTERS } from '../constants';
import unidecode from 'unidecode';

export function filterWikiDocumentsWithUserOrder(documents) {
    return documents.filter(
        (doc) => typeof doc.userOrder !== 'undefined' && doc.userOrder !== null,
    );
}

export function filterWikiDocumentsWithoutUserOrder(documents) {
    return documents.filter(
        (doc) => typeof doc.userOrder === 'undefined' || doc.userOrder === null,
    );
}

export function filterVisibleWikiDocuments(documents) {
    return documents.filter(
        (doc) => doc.docState === VISIBILITY_FILTERS.VISIBLE,
    );
}

export function getWikiDocumentsByParentId(documents, parentId = undefined) {
    if (typeof parentId === 'undefined' || parentId === null) {
        return documents.filter(
            (document) =>
                typeof document.parentId === 'undefined' ||
                document.parentId === null,
        );
    }

    return documents.filter((document) => document.parentId === parentId);
}

export function sortWikiDocumentsByUserOrderOrCreatedDateTimeAndTitle(
    documents,
) {
    const docsWithOrder = filterWikiDocumentsWithUserOrder(documents);
    docsWithOrder.sort((docA, docB) => {
        return docA.userOrder - docB.userOrder;
    });

    const lastUserOrder =
        docsWithOrder.length > 0
            ? docsWithOrder[docsWithOrder.length - 1].userOrder
            : 0;

    let docsWithoutOrder = filterWikiDocumentsWithoutUserOrder(documents);
    docsWithoutOrder.sort((docA, docB) => {
        if (docA.created !== docB.created) {
            return docA.created.localeCompare(docB.created);
        }

        return docA.title.localeCompare(docB.title);
    });

    docsWithoutOrder = docsWithoutOrder.map((doc, idx) => {
        return {
            ...doc,
            userOrder: lastUserOrder + idx,
        };
    });

    return {
        result: [...docsWithOrder, ...docsWithoutOrder],
        documentsWithOrder: docsWithOrder,
        documentsWithoutOrder: docsWithoutOrder,
    };
}

function normalizeDocumentTitle(title) {
    if (!title) {
        return '';
    }

    let result = unidecode(title);
    result = result.replace(/\[\?\]/gi, '').replace(/^\s+/gim, '');
    return result;
}

export function compareDocTitleByAlphabetAZ(docA, docB) {
    const titleA = normalizeDocumentTitle(docA.title);
    const titleB = normalizeDocumentTitle(docB.title);

    return titleA.localeCompare(titleB);
}

export function sortWikiDocumentsByAlphabetAZ(documents) {
    const result = documents.sort(compareDocTitleByAlphabetAZ);

    return {
        result: result,
        documentsWithOrder: [],
        documentsWithoutOrder: [],
    };
}

export function sortWikiDocumentsByModifiedDate(isAsc, documents) {
    const result = documents.sort((docA, docB) => {
        const changedA = docA.changed;
        const changedB = docB.changed;

        const resultOrder = changedA.localeCompare(changedB);

        return isAsc ? resultOrder : -1 * resultOrder;
    });

    return {
        result: result,
        documentsWithOrder: [],
        documentsWithoutOrder: [],
    };
}

export function getFirstVisibleDocumentId(documents) {
    const rootLevelDocs = getWikiDocumentsByParentId(
        filterVisibleWikiDocuments(documents),
    );

    if (!rootLevelDocs || rootLevelDocs.length === 0) {
        return;
    }

    const { result } =
        sortWikiDocumentsByUserOrderOrCreatedDateTimeAndTitle(rootLevelDocs);

    return result[0].id;
}
