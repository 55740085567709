import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'normalize.css/normalize.css';
import 'reactjs-popup/dist/index.css';
import { createGlobalStyle } from 'styled-components';
import 'url-search-params-polyfill';

import '../tailwind.css';

const GlobalStyle = createGlobalStyle`   
  * {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
      -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
      outline: none !important;
  } 
  
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;  
  }


  textarea, select, input, button { outline: none; }

  /** prevent ios zooming */
  select, .bp4-html-select select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] { font-size: 16px; }

    @media print {
        .Pane {
            width: 0 !important;
        }

        .subscribe-banner, [data-cy='page-navigation'] {
            display: none;
        }

        body * {
            visibility: hidden;
            overflow: visible !important;
        }

        .printable, .printable * {
            visibility: visible;
        }

        .printable {
            position: absolute;
            width: 100%;
            height: auto;

            &.editor {
                top: 3rem;
            }

            &.editor-title {
                top: 0;
            }

            .ck-content {
                border: none;
            }
        }

        .editor-header, .editor-title {
            background-color: white !important;
            color: black !important;
        }

        div.ck.ck-content {
            filter: invert(0) !important;
        }

        div.ck.ck-content img,iframe,a.mention {
            filter: invert(0) !important; 
        }
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    h1 {
        font-size: 1.5rem;
        font-weight: bold;        
        margin: 1rem 0;
    }

    h2 {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    h3 {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0.75rem 0;
    }

    h4 {
        font-size: 1rem;
        font-weight: bold;
        margin: 0.5rem 0;
    }

    p {
        margin-bottom: 10px;
        margin-top: 0;
    }

    mark {
        background-color: yellow;
    }

    .ant-message-custom-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ant-btn-primary {
        color: #fff;
        background-color: #1677ff;
    }

    .task-notification .ant-notification-notice-content {
        padding-right: 1.3rem;
    }

    .ck.ck-splitbutton {     
        white-space: nowrap;
    }

    .ios-scroll-disabled {
        overflow: hidden;
        position: fixed;
    }

    .onboarding-modal {
        @media(max-width: 768px) {
            .ant-modal-content {
                border-radius: 0px !important;
                padding: 0 1rem;
            }
        }
    }

    img.ant-image-preview-img {
        display: inline-block;
    }
`;

export default function Layout({ children }) {
    return (
        <div className='flex flex-col h-screen w-screen overflow-hidden bg-mst-bg-light-100 dark:bg-mst-bg-dark-100'>
            <GlobalStyle />
            {children}
        </div>
    );
}
