import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { CHANNEL_ID, SUBENTITY_ID, TEAM_ID } from '../constants';

export function getLocalContextFromSearchParams() {
    const urlParams = new URLSearchParams(window.location.search);

    const teamId = urlParams.get(TEAM_ID);
    const channelId = urlParams.get(CHANNEL_ID);
    const subEntityId = urlParams.get(SUBENTITY_ID);

    return { teamId, channelId, subEntityId };
}

export function useNav() {
    const navigate = useNavigate();
    const navigateRef = useRef({ navigate });
    useEffect(() => {
        navigateRef.current.navigate = navigate;
    }, [navigate]);
    return useCallback((location, state) => {
        navigateRef.current.navigate(location, state);
    }, []);
}
