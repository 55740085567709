import * as microsoftTeams from '@microsoft/teams-js';
import { Result } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { getLinkToTheWiki } from 'services/api';
import { getMsTeamsContext } from 'services/microsoftTeamsHelpers';
import { ConfigProvider, theme } from 'antd';

import PwIcon from './assets/pw-icon.svg';
import { useDarkMode } from 'components/ThemeLayoutProvider/hooks';

const ConfigPage = () => {
    const { isDarkTheme } = useDarkMode();
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        async function initialize() {
            const context = await getMsTeamsContext();
            const tenantId = context.user?.tenant?.id;

            if (!tenantId) {
                throw new Error('Failed to get tenant id');
            }

            const channelId = context.sharepoint
                ? context.sharepoint?.aadInfo?.tenantId
                : context.channel.id;

            const contentUrl = getLinkToTheWiki(
                tenantId,
                channelId,
                process.env.REACT_APP_REDIRECT_URI || window.location.origin,
            );

            microsoftTeams.pages.config.setValidityState(true);
            microsoftTeams.pages.config.registerOnSaveHandler((event) => {
                microsoftTeams.pages.config.setConfig({
                    entityId: channelId,
                    contentUrl: contentUrl,
                    removeUrl: `${
                        process.env.REACT_APP_REDIRECT_URI ||
                        'https://app.perfectwiki.xyz'
                    }/remove/`,
                    websiteUrl: contentUrl,
                    suggestedDisplayName: `Perfect Wiki`,
                });

                event.notifySuccess();
            });

            setFetching(false);
        }
        initialize();
    }, [setFetching]);

    return (
        <ConfigProvider
            theme={
                isDarkTheme && {
                    algorithm: theme.darkAlgorithm,
                }
            }>
            <div
                className='dark:bg-mst-bg-dark-100 dark:text-white
        bg-white  flex h-full w-full flex-col items-center justify-center'>
                <Result
                    icon={
                        <img
                            src={PwIcon}
                            alt='Perfect Wiki Logo'
                            className={`w-64 h-64 m-auto ${
                                fetching ? 'animate-pulse' : ''
                            }`}
                            loading='eager'
                        />
                    }
                    title={t(
                        'ConfigPage.AddChannelTitle',
                        'Welcome to Perfect Wiki',
                    )}
                    subTitle={
                        <Trans i18nKey='ConfigPage.AddChannelTitleDescription'>
                            <p>
                                Click <b>"Save"</b> to add Perfect Wiki tab.
                            </p>
                        </Trans>
                    }
                />
            </div>
        </ConfigProvider>
    );
};

export default ConfigPage;
