import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { generateSubEntityId } from 'services/microsoftTeamsHelpers';

import { isLocalStorageAvailable } from './localStorage';

export const LAST_VISITED_PAGE_STORAGE_KEY = 'pw.lastVisitedPage';
const MAX_HISTORY_LENGTH = 10;

function storeLastVisitedPage({
    teamId,
    channelId,
    subEntityId,
    pageId,
    heading,
}) {
    const visitedPages = getStoredData() || {};
    const pagesHistoryByChannel = visitedPages.pagesHistoryByChannel || {};
    const lastVisitedChannel = channelId;

    const newViewRecord = { teamId, channelId, subEntityId, pageId, heading };

    if (pageId) {
        if (Array.isArray(pagesHistoryByChannel[channelId])) {
            const pagesHistory = pagesHistoryByChannel[channelId];
            const filteredHistory = pagesHistory.filter(
                (p) => p.pageId !== newViewRecord.pageId
            );
            filteredHistory.push(newViewRecord);

            pagesHistoryByChannel[channelId] = filteredHistory.slice(
                -1 * MAX_HISTORY_LENGTH
            );
        } else {
            pagesHistoryByChannel[channelId] = [newViewRecord];
        }
    }

    if (isLocalStorageAvailable()) {
        localStorage.setItem(
            LAST_VISITED_PAGE_STORAGE_KEY,
            JSON.stringify({
                pagesHistoryByChannel,
                lastVisitedChannel,
            })
        );
    }
}

function getStoredData() {
    if (!isLocalStorageAvailable()) {
        return null;
    }

    const storedData = localStorage.getItem(LAST_VISITED_PAGE_STORAGE_KEY);
    if (!storedData) {
        return null;
    }

    try {
        const visitedPages = JSON.parse(storedData);
        return visitedPages;
    } catch (err) {
        console.error('Failed to parse visited pages', err);
    }

    return null;
}

export function getLastVisitedChannel() {
    const { lastVisitedChannel } = getStoredData() || {};
    return lastVisitedChannel;
}

export function getLastVisitedPages(channelId) {
    if (!channelId) {
        return [];
    }

    const { pagesHistoryByChannel = {} } = getStoredData() || {};
    if (!Array.isArray(pagesHistoryByChannel[channelId])) {
        return [];
    }

    return pagesHistoryByChannel[channelId];
}

export function getLastVisitedPage(channelId) {
    const history = getLastVisitedPages(channelId);
    if (history.length === 0) {
        return null;
    }

    return history[history.length - 1];
}

export function setCurrentDocument(
    navigate,
    location,
    teamId,
    channelId,
    pageId,
    heading
) {
    const curPath = location.pathname;
    const subEntityId = generateSubEntityId(pageId, heading);
    const nextPath = pageId
        ? `/${teamId}/${channelId}/${subEntityId}`
        : `/${teamId}/${channelId}`;

    storeLastVisitedPage({
        teamId,
        channelId,
        subEntityId,
        pageId,
        heading,
    });

    navigate(nextPath, { state: { prevPath: curPath } });
}

export function useSetCurrentDocument() {
    const navigate = useNavigate();
    const location = useLocation();

    const setDocument = useCallback(
        ({ teamId, channelId, pageId }) => {
            setCurrentDocument(navigate, location, teamId, channelId, pageId);
        },
        [navigate, location]
    );

    return setDocument;
}
