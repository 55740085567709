import { useCallback, useEffect, useState } from 'react';
import {
    detectDarkTheme,
    savePWThemeToLocalStorage,
} from 'services/localStorage';

export function useDarkMode() {
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    useEffect(() => {
        const darkThemeCondition = detectDarkTheme();

        if (darkThemeCondition) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }

        setIsDarkTheme(darkThemeCondition);
    }, [setIsDarkTheme]);

    const reloadPageAfterDelay = useCallback(
        () =>
            setTimeout(() => {
                window.location.reload();
            }, 300),
        []
    );

    const switchTheme = useCallback(
        (theme) => {
            savePWThemeToLocalStorage(theme);
            reloadPageAfterDelay();
        },
        [reloadPageAfterDelay]
    );

    return {
        isDarkTheme,
        switchTheme,
    };
}
