import { Trans } from 'react-i18next';

import { NotificationTypes } from 'components/Notification';
import Spinner from 'components/Spinner';

export const TaskName = {
    GeneratePdf: 'generate-pdf',
    GenerateArchivedHtml: 'generate-archived-html',
    GenerateArchivedPdf: 'generate-archived-pdf',
    TrashPages: 'trash-pages',
    TrashAllPages: 'trash-all-pages',
    RemovePages: 'remove-pages',
    RemoveAllPages: 'remove-all-pages',
    CopyPages: 'copy-pages',
    CopyAllPages: 'copy-all-pages',
    MovePages: 'move-pages',
};

export const TaskInstance = {
    [TaskName.GeneratePdf]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ fileName }) => (
                    <Trans i18nKey='TaskGeneratePdf.NotificationInfo'>
                        Exporting "{{ fileName: fileName }}" to PDF...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ fileUrl, fileName }) => (
                    <>
                        <a
                            href={fileUrl}
                            target='_blank'
                            rel='noreferrer nofollow'>
                            <Trans i18nKey='TaskGeneratePdf.NotificationSuccess'>
                                Click to open "{{ fileName: fileName }}" PDF
                            </Trans>
                        </a>
                    </>
                ),
                duration: 0,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskGeneratePdf.NotificationError'>
                        For some reason we can’t export this file to PDF, try
                        again later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.GenerateArchivedHtml]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ fileName }) => (
                    <Trans i18nKey='TaskGenerateArchivedHtmlKb.NotificationInfo'>
                        Creating html archive of "{{ fileName: fileName }}"
                        knowledge base...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ fileUrl, fileName }) => (
                    <>
                        <a
                            href={fileUrl}
                            target='_blank'
                            rel='noreferrer nofollow'>
                            <Trans i18nKey='TaskGenerateArchivedHtml.NotificationSuccess'>
                                Click to download "{{ fileName: fileName }}
                                .html.zip"
                            </Trans>
                        </a>
                    </>
                ),
                duration: 0,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskGenerateArchivedHtml.NotificationError'>
                        For some reason we can’t export this html files to
                        archive, try again later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.GenerateArchivedPdf]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ fileName }) => (
                    <Trans i18nKey='TaskGenerateArchivedPdfKb.NotificationInfo'>
                        Creating pdf archive of "{{ fileName: fileName }}"
                        knowledge base...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ fileUrl, fileName }) => (
                    <>
                        <a
                            href={fileUrl}
                            target='_blank'
                            rel='noreferrer nofollow'>
                            <Trans i18nKey='TaskGenerateArchivedPdf.NotificationSuccess'>
                                Click to download "{{ fileName: fileName }}
                                .pdf.zip"
                            </Trans>
                        </a>
                    </>
                ),
                duration: 0,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskGenerateArchivedPdf.NotificationError'>
                        For some reason we can’t export this pdf files to
                        archive, try again later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.TrashPages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskTrashPages.NotificationInfo'>
                        Moving page "{{ pageTitle: pageTitle }}" and all
                        subpages to trash...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskTrashPages.NotificationSuccess'>
                        Page "{{ pageTitle: pageTitle }}" and all subpages moved
                        to trash
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ pageTitle, error }) => (
                    <Trans i18nKey='TaskTrashPages.NotificationError'>
                        For some reason we can’t move page "
                        {{ pageTitle: pageTitle }}" to trash, try again later. (
                        {{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.TrashAllPages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: () => (
                    <Trans i18nKey='TaskTrashAllPages.NotificationInfo'>
                        Moving all pages to trash...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: () => (
                    <Trans i18nKey='TaskTrashAllPages.NotificationSuccess'>
                        All pages moved to trash
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskTrashAllPages.NotificationError'>
                        For some reason we can’t move page all pages to trash,
                        try again later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.RemovePages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskRemovePages.NotificationInfo'>
                        Removing "{{ pageTitle: pageTitle }}" page from trash...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskRemovePages.NotificationSuccess'>
                        Page "{{ pageTitle: pageTitle }}" has been removed from
                        trash successfully
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ pageTitle, error }) => (
                    <Trans i18nKey='TaskRemovePages.NotificationError'>
                        For some reason we can’t remove "
                        {{ pageTitle: pageTitle }}" page from trash, try again
                        later. ({error})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.RemoveAllPages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: () => (
                    <Trans i18nKey='TaskRemoveAllPages.NotificationInfo'>
                        Removing all pages...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: () => (
                    <Trans i18nKey='TaskRemoveAllPages.NotificationSuccess'>
                        All pages were removed successfully
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskRemoveAllPages.NotificationError'>
                        For some reason we can’t remove all pages, try again
                        later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.CopyAllPages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: () => (
                    <Trans i18nKey='TaskCopyAllPagesKb.NotificationInfo'>
                        Copying all pages to another knowledge base...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: () => (
                    <Trans i18nKey='TaskCopyAllPages.NotificationSuccess'>
                        All pages copied!
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ error }) => (
                    <Trans i18nKey='TaskCopyAllPagesKb.NotificationError'>
                        For some reason we can’t copy all pages to another
                        knowledge base, try again later. ({{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.CopyPages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskCopyPages.NotificationInfo'>
                        Copying page "{{ pageTitle: pageTitle }}"...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ pageTitle, onLinkClick }) => (
                    <>
                        <Trans i18nKey='TaskCopyPages.NotificationSuccess'>
                            Page "{{ pageTitle: pageTitle }}" copied.{' '}
                            <button
                                onClick={onLinkClick}
                                className='text-[#1890ff]'>
                                Open copied page
                            </button>
                        </Trans>
                    </>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ pageTitle, error }) => (
                    <Trans i18nKey='TaskCopyPages.NotificationError'>
                        For some reason we can’t copy page "
                        {{ pageTitle: pageTitle }}", try again later. (
                        {{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
    [TaskName.MovePages]: {
        notifications: {
            [NotificationTypes.Info]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskMovePages.NotificationInfo'>
                        Moving page "{{ pageTitle: pageTitle }}"...
                    </Trans>
                ),
                icon: <Spinner fontSize={24} />,
                duration: 0,
            },
            [NotificationTypes.Success]: {
                getDescription: ({ pageTitle }) => (
                    <Trans i18nKey='TaskMovePages.NotificationSuccess'>
                        Page "{{ pageTitle: pageTitle }}" has been moved
                        successfully.
                    </Trans>
                ),
                duration: 5,
            },
            [NotificationTypes.Error]: {
                getDescription: ({ pageTitle, error }) => (
                    <Trans i18nKey='TaskMovePages.NotificationError'>
                        For some reason we can’t move page "
                        {{ pageTitle: pageTitle }}", try again later. (
                        {{ error: error }})
                    </Trans>
                ),
                duration: 10,
            },
        },
    },
};

export const TaskStatus = {
    Completed: 'completed',
    Failed: 'failed',
    NotFound: 'task-not-found',
    Delayed: 'delayed',
    Active: 'active',
    Waiting: 'waiting',
    Paused: 'paused',
    Stuck: 'stuck',
};
