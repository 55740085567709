import { useEffect } from 'react';

import { identifyUserV2 } from 'services/api';
import * as microsoftTeams from '@microsoft/teams-js';
import * as msalHelper from 'services/msalHelpers';

import FullScreenLoading from 'components/FullScreenFetching';

const SignInWithMicrosoft = () => {
    useEffect(() => {
        async function endAuthFlow() {
            try {
                await microsoftTeams.app.initialize();

                const msalInstance = msalHelper.getMsalInstance();
                const tokenResponse =
                    await msalInstance.handleRedirectPromise();

                if (tokenResponse === null) {
                    throw new Error(
                        'Microsoft Authentication Servers do not respond',
                    );
                }

                await identifyUserV2({
                    provider: 'microsoft',
                    token: tokenResponse.accessToken,
                });

                microsoftTeams.authentication.notifySuccess(
                    tokenResponse.accessToken,
                );
            } catch (err) {
                microsoftTeams.app.isInitialized() &&
                    microsoftTeams.authentication.notifyFailure(
                        err?.response?.data?.message ||
                            err ||
                            'Failed to authenticate with Microsoft',
                    );
            }
        }

        endAuthFlow();
    }, []);

    return <FullScreenLoading message='Almost done...' />;
};

export default SignInWithMicrosoft;
