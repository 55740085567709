import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export function useGetVisiblePagesCallback() {
    const queryClient = useQueryClient();

    return useCallback(
        (channelId) => {
            const queryData =
                queryClient.getQueryData(['pages', channelId, 'visible']) || {};
            const { pages } = queryData;

            return pages || [];
        },
        [queryClient]
    );
}

export function useGetVisiblePages(channelId) {
    const queryClient = useQueryClient();
    const queryData =
        queryClient.getQueryData(['pages', channelId, 'visible']) || {};

    const { pages } = queryData;

    return pages || [];
}

export function useGetVisiblePage(channelId, pageId) {
    const queryClient = useQueryClient();
    const page =
        queryClient.getQueryData(['pages', channelId, 'visible', pageId]) || {};

    return page || {};
}

export function useSetVisiblePage_OBSOLETE(channelId) {
    const queryClient = useQueryClient();

    return (pageId, pageData) => {
        queryClient.setQueryData(
            ['pages', channelId, 'visible', pageId],
            pageData
        );
    };
}

export function useSetVisiblePage() {
    const queryClient = useQueryClient();

    return ({ channelId, pageId, pageData }) => {
        queryClient.setQueryData(
            ['pages', channelId, 'visible', pageId],
            pageData
        );
    };
}

export function useSetVisiblePageList() {
    const queryClient = useQueryClient();

    return (channelId, updatePagesListFunc) => {
        queryClient.setQueryData(['pages', channelId, 'visible'], (old) => {
            if (!old) {
                return;
            }
            return {
                ...old,
                pages: updatePagesListFunc(old.pages),
            };
        });
    };
}
