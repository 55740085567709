import { AppNotification } from 'components/Notification';
import { identifyUserV2 } from 'services/api';

const CLIENT_ID =
    '776118888019-sn5ni0levotmc0eoagc2duo8grraafkl.apps.googleusercontent.com';
const SCOPES = 'email profile openid';
const API_KEY = 'AIzaSyBvfoXBRNlUoVxbADzFJmHj6RKlQ8mAugQ';

let service = null;

function handleCredentials(
    nextUrl,
    onAuthorizationStart,
    onAuthorizationFinish,
) {
    return async (resp) => {
        if (onAuthorizationStart) {
            onAuthorizationStart();
        }

        if (!resp) {
            if (onAuthorizationFinish) {
                onAuthorizationFinish();
            }
            return;
        }

        try {
            await identifyUserV2({
                provider: 'google',
                token: resp.credential,
            });

            window?.location.replace(nextUrl);
        } catch (error) {
            AppNotification.error({
                description:
                    error.response?.data?.message ||
                    error.message ||
                    error.toString(),
            });

            if (onAuthorizationFinish) {
                onAuthorizationFinish();
            }
        }
    };
}

function getClientInstance(
    nextUrl = '/',
    onAuthorizationStart,
    onAuthorizationFinish,
) {
    if (!window.google) {
        return null;
    }

    window?.google.accounts.id.initialize({
        client_id: CLIENT_ID,
        callback: handleCredentials(
            nextUrl,
            onAuthorizationStart,
            onAuthorizationFinish,
        ),
        apiKey: API_KEY,
        discoveryDocs: [],
        scope: SCOPES,
        ux_mode: 'popup',
        login_uri: '/signin',
    });

    service = window?.google;

    return service;
}

export async function prompt({
    signInWithGoogleBtnEl,
    nextUrl,
    onAuthorizationStart,
    onAuthorizationFinish,
}) {
    const googleClient = getClientInstance(
        nextUrl,
        onAuthorizationStart,
        onAuthorizationFinish,
    );
    if (!googleClient) {
        return;
    }

    return new Promise((resolve, reject) => {
        googleClient.accounts.id.renderButton(signInWithGoogleBtnEl, {
            theme: 'filled_black',
            type: 'standard',
            size: 'large',
            text: 'signin_with',
            shape: 'square',
            width: 300,
        });

        // TODO: temporarily turned of one tap login
        /*
        googleClient.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
                reject({
                    error: notification.getNotDisplayedReason(),
                });
                return;
            }

            if (notification.isSkippedMoment()) {
                reject({
                    error: notification.getSkippedReason(),
                });
                return;
            }

            resolve(notification);
        });
        */
    });
}

export function signOut() {
    const googleClient = getClientInstance();
    googleClient?.accounts.id.disableAutoSelect();
}
