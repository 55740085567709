import { useEffect } from 'react';
import { authUserWithMsalRedirect } from 'services/msalHelpers';
import * as microsoftTeams from '@microsoft/teams-js';

import FullScreenLoading from 'components/FullScreenFetching';

const SignInWithMicrosoft = () => {
    useEffect(() => {
        async function startAuthFlow() {
            try {
                await microsoftTeams.app.initialize();

                const context = await microsoftTeams.app.getContext();
                const loginHint = context.user.loginHint;
                const tenantId = context.user.tenant?.id;
                await authUserWithMsalRedirect(loginHint, tenantId);
            } catch (err) {
                console.error(err);
            }
        }

        startAuthFlow();
    }, []);

    return <FullScreenLoading message='Signing you in with Microsoft...' />;
};

export default SignInWithMicrosoft;
